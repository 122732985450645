@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('/fonts/Inter/Inter-VariableFont_slnt,wght.ttf') format('truetype');
}

// @font-face {
//   font-display: swap;
//   font-family: "Playfair Display";
//   font-weight: 400;
//   src: url("../../../public/fonts/PlayfairDisplay-Regular.woff") format("woff2"),
//     url("../../../public/fonts/PlayfairDisplay-Regular.woff") format("woff");
// }

// @font-face {
//   font-display: swap;
//   font-family: 'Archivo';
//   font-weight: 400;
//   src: url('../../../public/fonts/Archivo-Regular.woff') format('woff2'),
//     url('../../../public/fonts/Archivo-Regular.woff') format('woff');
// }

body.fixed {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.nivo-pie {
  path {
    cursor: pointer;
  }
}
